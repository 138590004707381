<template>
  <v-container class="main-scope">
    <div class="text-center">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="50"></v-progress-circular>
      </v-overlay>
      <div class="icon" v-if="showCars === 'Авто'">
        <svg height="200" width="200" xmlns="http://www.w3.org/2000/svg" :style="iconStyle">
          <image href="https://a-prokat.storage.yandexcloud.net/1/ea7c3bd7-bea3-41a7-9d66-1ccc3a735438/logo-circle.png"
            height="200" width="200" @click="clickIcon" />
        </svg>
      </div>
      <div class="icon" v-else>
        <svg height="200" width="200" xmlns="http://www.w3.org/2000/svg">
          <image href="https://a-prokat.storage.yandexcloud.net/1/5a994e92-646b-47f4-b691-528ac98a0ff7/hut.png"
            height="200" width="200" />
        </svg>
      </div>
      <div class="text__head mb-9">
        <h2>Аренда авто</h2>
      </div>
      <!-- <div class="switcher">
        <v-radio-group v-model="showCars" row>
          <div class="mr-3">Я ищу</div>
          <v-radio
            v-for="n in options"
            :key="n"
            :label="n"
            :value="n"
          ></v-radio>
        </v-radio-group>
      </div> -->
      <div style="height: 250px; width: 150px; position: absolute; top: 40px" v-if="notProduction">
        <v-text-field v-model="test"> </v-text-field>
        <v-btn @click="testOne" class="my-1">log this id</v-btn>
        <br />
        <v-btn @click="testBad1" class="my-1">bad method</v-btn>
        <v-btn @click="testBad2" class="my-1">bad resource</v-btn>
        <v-btn @click="structure" class="my-1">get Structure</v-btn>
        <br />
        <v-text-field v-model="testFieldOne" placeholder="name1"></v-text-field>
        <v-text-field v-model="testValueOne" placeholder="value1"></v-text-field>
        <v-text-field v-model="testFieldTwo" placeholder="name2"></v-text-field>
        <v-text-field v-model="testValueTwo" placeholder="value2"></v-text-field>
        <v-btn @click="testAll" class="my-1">log all</v-btn>
        <br />
        <v-text-field v-model="textTest" placeholder="text in record"></v-text-field>
        <div>Текс и мерс в заявку</div>
        <a href="https://erp.a-prokat.com/#/section/3/catalog/103/view/1817/records/81603/main">record
        </a>
        <v-btn @click="patchOne" class="my-1">patch </v-btn>
      </div>
      <div class="selectors">
        <v-menu class="time-selector" ref="dateAtMenu" v-model="dateAtMenu" :close-on-content-click="true"
          :nudge-right="40" :return-value.sync="filters.dateAt" transition="scale-transition" offset-y max-width="290px"
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense v-model="dateText" label="Начало аренды" append-icon="mdi-calendar-month" readonly
              v-bind="attrs" v-on="on" outlined @click:append="openDatePicker"></v-text-field>
          </template>
          <v-date-picker :min="minDate()" show-adjacent-months="true" first-day-of-week="1" no-title v-if="dateAtMenu"
            v-model="filters.dateAt" full-width @click:date="$refs.dateAtMenu.save(filters.dateAt)"></v-date-picker>
        </v-menu>

        <v-select dense class="city-selector" item-color="blue darken-3" label="Выберите город" :items="notProduction
            ? showCars == 'Авто'
              ? allCities
              : cityIdsWithApartments
            : showCars == 'Авто'
              ? allCities.filter((x) => x.id != 696)
              : cityIdsWithApartments.filter((x) => x.id != 696)
          " item-text="name" item-value="id" outlined no-data-text="Сайт временно недоступен" @change="goToActive">
        </v-select>
      </div>
      <div class="marketing-text text-subtitle-2">
        <p class="text-item">Без залога</p>
        <p class="text-item">
          {{
            (showCars == 'Авто' && 'Без лимита пробега') || 'Без посредников'
          }}
        </p>
        <p class="text-item">Без посещения офиса</p>
        <p class="text-item">
          {{
            (showCars == 'Авто' && 'Выдача авто за 5 минут') ||
            'Мнгновенное решение'
          }}
        </p>
      </div>
    </div>
    <!-- <div class="backContainer" v-if="showSnowOnMobile">
      <div class="snow" :style="snowStyle"></div>
      <div class="tree"></div>
    </div> -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { find } from 'lodash';
export default {
  metaInfo() {
    return {
      title: 'А-прокат',
    };
  },
  data: () => ({
    loading: false,
    test: 0,
    textTest: '',
    testFieldOne: '',
    testValueOne: '',
    testFieldTwo: '',
    testValueTwo: '',
    iconStuf: undefined,
    isMobile: false,
    dateAtMenu: false,
    showCars: 'Авто',
    options: ['Авто', 'Квартиру'],
    origin: window.origin,
    listOfCar: ['https://app.a-prokat.com', 'http://app.a-prokat.com'],
    listOfHut: ['https://hutsharing.ru', 'http://hutsharing.ru'],
  }),
  computed: {
    ...mapGetters([
      'allCities',
      'filters',
      'allFilials',
      'socket',
      'showSnowOnMobile',
    ]),
    dateText() {
      return moment(this.filters.dateAt).format('DD.MM.YYYY');
    },
    snowStyle() {
      return `width: ${window.innerWidth}px; height: ${window.innerWidth /
        4}px;`;
    },
    iconStyle() {
      return this.iconStuf;
    },
    notProduction() {
      let flag = false;
      if (
        process.env.VUE_APP_MODE === 'development' ||
        process.env.NODE_ENV === 'development'
      )
        flag = true;
      return flag;
    },
    cityIdsWithApartments() {
      const citys = this.allFilials
        .filter((x) => x.hasApartment)
        .map((y) => {
          const obj = {};
          obj.id = y.cityId;
          obj.name = y.cityTitle;
          return obj;
        });
      return citys;
    },
  },

  methods: {
    ...mapMutations(['changeCurrentCityId']),
    clickIcon() {
      console.log('clc!');
      this.iconStuf = `filter: drop-shadow(0px 3px 5px rgb(143, 24, 24)) drop-shadow(0px 18px 30px #e4d020);`;
      setTimeout(() => {
        this.iconStuf = undefined;
      }, 500);
    },
    openDatePicker() {
      this.$refs.dateAtMenu.isActive = true;
    },
    structure() {
      const rnd = uuidv4();
      this.socket.emit('request', {
        resource: 'booking',
        method: 'structure',
        guid: rnd,
      });
      this.socket.once(`response:${rnd}`, (data) => {
        console.log('response io:', data);
      });
    },
    testOne() {
      const rnd = uuidv4();
      this.socket.emit('request', {
        resource: 'booking',
        method: 'get',
        guid: rnd,
        data: {
          id: this.test,
        },
      });
      this.socket.once(`response:${rnd}`, (data) => {
        console.log('response io:', data);
      });
    },
    patchOne() {
      const rnd = uuidv4();
      this.socket.emit('request', {
        resource: 'booking',
        method: 'patch',
        guid: rnd,
        data: {
          id: 81603,
          allComents: this.textTest,
          assetLink: [{ catalogId: 32, recordId: 314 }],
        },
      });
      this.socket.once(`response:${rnd}`, (data) => {
        console.log('response io:', data);
      });
    },
    testBad1() {
      const rnd = uuidv4();
      this.socket.emit('sendMessageByTemplate', {
        bookingId: 93478,
        noteId: this.textTest,
        guid: rnd,
        // data: {
        //   id: 81603,
        //   allComents: this.textTest,
        //   assetLink: [{ catalogId: 32, recordId: 314 }],
        // },
      });
      this.socket.once(`sendMessageByTemplate:${rnd}`, (data) => {
        console.log('response io:', data);
      });
    },
    testBad2() {
      const rnd = uuidv4();
      this.socket.emit('request', {
        resource: 'bookwding',
        method: 'patchrgr',
        guid: rnd,
        data: {
          id: 81603,
          allComents: this.textTest,
          assetLink: [{ catalogId: 32, recordId: 314 }],
        },
      });
      this.socket.once(`response:${rnd}`, (data) => {
        console.log('response io:', data);
      });
    },
    testAll() {
      const rnd = uuidv4();
      // const data = {};
      // if (this.testFieldOne && this.testValueOne)
      //   data[this.testFieldOne] = eval(this.testValueOne);
      // if (this.testFieldTwo && this.testValueTwo)
      //   data[this.testFieldTwo] = eval(this.testValueTwo);

      this.socket.emit('request', {
        resource: 'booking',
        method: 'getAll',
        guid: rnd,
        // data: data,
      });
      this.socket.once(`response:${rnd}`, (data) => {
        console.log('response io:', data);
      });
    },
    minDate() {
      return moment().format('YYYY-MM-DD');
    },
    goToActive(val) {
      this.filters.cityId = val;
      const cityFlitered = find(this.allCities, (o) => {
        return o.id === this.filters.cityId;
      });
      this.changeCurrentCityId(cityFlitered.id);
      // Машины
      if (this.showCars == this.options[0]) {
        this.$router.push(
          `/cars/${cityFlitered.nameTranslite}?dateAt=${moment(
            this.filters.dateAt
          ).format('YYYY-MM-DD')}`
        );
        //Квартиры
      } else {
        this.$router.push(
          `/apartments/${cityFlitered.nameTranslite}?dateAt=${moment(
            this.filters.dateAt
          ).format('YYYY-MM-DD')}`
        );
      }
    },
    chooseCity() {
      this.click = true;
    },
    ...mapActions(['getCitites']),
  },
  async created() {
    const url = window.origin;
    if (this.listOfHut.includes(url)) {
      this.showCars = 'Квартиру';
    }
    // this.loading = true;
    // if (!this.allCities.length > 0) await this.getCitites();
    // this.loading = false;
    // 
  },
};
</script>

<style lang="scss">
.main-scope {
  .icon {
    margin: 40px auto 30px;
    transition-property: top;
    transition-duration: 2s;

    svg {
      margin-left: auto;
      filter: drop-shadow(0px 3px 5px black) drop-shadow(0px 18px 30px #1565c0);
      -webkit-transition: -webkit-filter 500ms ease-in-out;
    }
  }

  .switcher {
    // display: flex;
    // justify-content: center;
    // justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 5px;
    max-width: 420px;
    margin: auto;
  }

  .v-input--switch__thumb {
    color: #ffee58 !important;
  }

  .v-input--switch__track {
    color: #ffee58 !important;
  }

  .primary--text {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }

  .text__head {
    margin: 40px auto 20px;
  }

  .selectors {
    z-index: 100;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 5px;
    max-width: 420px;
    margin: auto;
  }

  .time-selector {
    display: block;
  }

  .city-selector {
    display: block;
  }

  .marketing-text {
    opacity: 0.5;
    width: 200px;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px);
    margin-bottom: 10px;

    p {
      margin-bottom: 5px !important;
    }

    z-index: 1;
  }

  .text-center {
    transition-property: margin;
    transition-duration: 1s;
  }

  .backContainer {
    position: absolute;
    display: block;
    bottom: 0;
    margin: -1em;
    margin-left: -2em;
    width: 100%;
    opacity: 0.9;
  }

  .snow {
    // display: block;
    position: absolute;
    left: 4px;
    bottom: 0;
    height: 100px;
    max-height: 200px;
    background-image: url('https://storage.yandexcloud.net/a-prokat/1/1d926df1-36d3-4b16-8d30-ceba33e85373/free-png.ru-463.png');
    -moz-background-size: 100%;
    /* Firefox 3.6+ */
    -webkit-background-size: 100%;
    /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%;
    /* Opera 9.6+ */
    background-size: 100%;
    background-position: bottom center;
    z-index: 0;
  }

  .tree {
    position: absolute;
    bottom: 0px;
    right: -4px;
    width: 300px;
    height: 300px;
    background-image: url('https://storage.yandexcloud.net/a-prokat/1/f9a82ee2-8922-4230-a240-b535688d1e14/elkapravo.png');
    background-size: contain;
    background-position: bottom right;
    margin: 0;
    margin-right: -2em;
    pointer-events: none;
    z-index: 0;
  }

  @media (min-width: 900px) {
    .text-center {
      margin-top: 125px;
      transition-property: margin;
      transition-duration: 1s;
    }

    .marketing-text {
      bottom: 20px;
    }

    .snow {
      display: none;
    }

    .tree {
      display: none;
    }
  }

  @media (max-height: 770px) {
    .marketing-text {
      display: none;
    }
  }

  @media (max-height: 570px) {
    .icon {
      margin: 0;
    }
  }

  @media (max-height: 860px) and (min-width: 900px) {
    .marketing-text {
      display: none;
    }
  }
}
</style>
